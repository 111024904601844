import React from 'react';
import LandingPage from './components/LandingPage';
import Header from './components/Header';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react';
import logo from './logo.svg'; // Update the path to where your logo file is
import Fonts from './fonts.js';
import theme from './theme'

const App = () => {
  return (
    
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box minH="100vh" minW="100vw">
        {/* <Header logo={logo} /> */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* Define more routes as needed */}
          {/* <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} /> */}
        </Routes>
      </Box>
    </ChakraProvider>
  );
}

export default App;

